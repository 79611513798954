import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import { I18n, Translate } from 'react-redux-i18n';
import Paper from '@material-ui/core/Paper';
import PriceCategory from './PriceCategory';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import { getSigle } from '../selectors/global.selector';
import { connect } from 'react-redux';

class Prices extends Component {
	constructor(props) {
		super(props);

		this.state = {
			retailPriceActive: false,
		};

		this.onSwitchChange = this.onSwitchChange.bind(this);
		this.onPriceChange = this.onPriceChange.bind(this);
		this.onChangeSwitchRetail = this.onChangeSwitchRetail.bind(this);
		this.onChangeRetailPriceType = this.onChangeRetailPriceType.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			checked: nextProps.checked,
			price: nextProps.price,
			retailPriceActive: nextProps.idRetailPriceType != 0,
		});
	}

	onSwitchChange(ev, value) {
		ev.preventDefault();

		this.props.onChange({
			id: this.props.id,
			price: value ? this.props.default_price : '0.00',
			checked: value,
		});
	}

	onPriceChange(ev, value) {
		ev.preventDefault();
		this.props.onChange({
			id: this.props.id,
			price: value,
			checked: this.props.checked,
		});
	}

	onChangeSwitchRetail(e) {
		e.preventDefault();

		this.setState({ retailPriceActive: e.target.checked });
		this.props.onRetailPriceTypeChange(
			e.target.checked ? this.props.retailPriceTypes[0].id : '0'
		);
	}

	onChangeRetailPriceType(e) {
		e.preventDefault();

		this.props.onRetailPriceTypeChange(e.target.value);
	}

	components = {
		paper: Paper,
	};

	handlePrice = ev => {
		let value = ev.target.value;
		value = value.replace(/,/g, '.');
		value = value.replace(/[^.\d]/g, '');
		this.props.onPriceChange(value);
	};

	render() {
		const {
			price,
			priceCategories,
			selectedPriceCategories,
			onPriceCategoryChange,
			onPriceChange,
			canBeRetailled,
			retailPriceTypes,
			idRetailPriceType,
			sigle,
			displayPrice,
			updateWrongPrice,
		} = this.props;
		const { retailPriceActive } = this.state;
		const TagName = this.props.tag;

		const unit = retailPriceTypes.find(r => r.id === idRetailPriceType);

		const priceCategoriesSelected = selectedPriceCategories.map(s => {
			return s.id;
		});

		const retailsOptions = retailPriceTypes.map(rpt => {
			return (
				<MenuItem key={rpt.id} value={rpt.id}>
					<Translate value={rpt.name} />
				</MenuItem>
			);
		});

		return (
			<TagName
				style={{
					display: 'inline-block',
					width: '100%',
					textAlign: 'center',
					minHeight: 130,
					padding: '10px 10px 20px 10px',
				}}>
				{displayPrice ? (
					<h2>
						<Translate value={'product.price'} />
					</h2>
				) : (
					''
				)}
				<Row center={'xs'}>
					<Col xs={10}>
						<Row>
							<Col xs={12}>
								<Input
									type={'text'}
									label={I18n.t('product.form.price_default')}
									value={price || ''}
									onChange={this.handlePrice}
									onClick={e => {
										e.preventDefault();
										if (price == 0) {
											onPriceChange('');
										}
									}}
									className="form-textfield price-container"
									name={'product_price_default'}
									fullWidth={true}
									endAdornment={
										<InputAdornment position="end">
											{' '}
											{unit ? sigle + '/' + I18n.t(unit.unit) : sigle}
										</InputAdornment>
									}
								/>
							</Col>
						</Row>

						{canBeRetailled ? (
							<Row style={{ marginTop: 15 }}>
								<Col xs={6}>
									<FormControlLabel
										control={
											<Switch
												onChange={this.onChangeSwitchRetail}
												checked={retailPriceActive}
												className={'switch_product_price'}
											/>
										}
										label={I18n.t('product.form.retail_price')}
									/>
								</Col>
								<Col
									xs={6}
									style={{ display: retailPriceActive ? 'inherit' : 'none' }}>
									<Select
										value={idRetailPriceType}
										onChange={this.onChangeRetailPriceType}>
										{retailsOptions}
									</Select>
								</Col>
							</Row>
						) : (
							''
						)}
					</Col>
				</Row>

				<div className={'priceCategoryList'}>
					{priceCategories.map(pc => {
						if (pc.active) {
							let index = priceCategoriesSelected.indexOf(pc.id);
							let prixAssocie =
								index > -1 ? selectedPriceCategories[index] : null;

							let pricePC = prixAssocie ? prixAssocie.price : '';
							let checked = !!prixAssocie;
							return (
								<PriceCategory
									key={pc.id}
									name={pc.name}
									id={pc.id}
									price={pricePC}
									checked={checked}
									default_price={price}
									onChange={onPriceCategoryChange}
									updateWrongPrice={updateWrongPrice}
								/>
							);
						} else {
							return '';
						}
					})}
				</div>
			</TagName>
		);
	}
}

Prices.propTypes = {
	price: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.number.isRequired,
	]),
	priceCategories: PropTypes.array.isRequired,
	selectedPriceCategories: PropTypes.array.isRequired,
	onPriceChange: PropTypes.func.isRequired,
	onPriceCategoryChange: PropTypes.func.isRequired,
	onRetailPriceTypeChange: PropTypes.func,
	canBeRetailled: PropTypes.bool,
	idRetailPriceType: PropTypes.string,
	displayPrice: PropTypes.bool,
	tag: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

Prices.defaultProps = {
	canBeRetailled: false,
	idRetailPriceType: '',
	retailPriceTypes: [],
	displayPrice: true,
	tag: Paper,
};

const mapStateToProps = state => {
	const { entity, user } = state;
	const { variable, priceCategory } = entity;

	return {
		sigle: getSigle(variable),
		priceCategories: priceCategory,
	};
};

export default connect(mapStateToProps)(Prices);
